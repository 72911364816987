.purpleBackground {
    background-color: #1C1530;
}

.logoWrapper { 
    border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.logo {
    width: 35px;
    height:  35px;
}
.purpleTextMasterWrapper { 
    /* border-left: 1px solid #FFFFFF; */
}

.purpleTextWrapper { 
    background: linear-gradient(100.21deg, #FF409A -24%, #C438EF 63.96%);
    border-radius: 25px;
}

.accountNumberWithEllipse {
    max-width: 8ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}